import React from "react";
import { graphql } from "gatsby";
import Header from "../components/Header";
import LeftSideBar from "../components/LeftSideBar";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useI18next, Trans } from "gatsby-plugin-react-i18next";
import Moment from "react-moment";
import theme from "../config/theme";
import "../styles/layout.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TagList from "../components/TagList";

import { Container, Box, Card, CardContent, Breadcrumbs, Typography, Button } from "@mui/material";

const TagTemplate = props => {
    const { language, languages, originalPath } = useI18next();
    const { data } = props;

    console.log(props);
    return (
        <ThemeProvider theme={theme}>
            <Container disableGutters maxWidth={false}>
                <Header props={props} showMenu={true} />
                <Box display={"flex"}>
                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        <LeftSideBar subcategories={props.data.subcategories ? props.data.subcategories.edges : []} slug={props.pageContext.slug} variant={"permanent"} isOpen={true} />
                    </Box>
                    {data.tag && (
                        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                            <TagList
                                tags={[data.tag]}
                                articles={
                                    data.articles
                                        ? data.articles.edges.map(({ node }) => {
                                              return {
                                                  id: node.id,
                                                  locale: node.locale,
                                                  order: node.order,
                                                  slug: node.slug,
                                                  title: node.title,
                                              };
                                          })
                                        : []
                                }
                            ></TagList>
                        </Box>
                    )}
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default TagTemplate;

export const query = graphql`
    query ($slug: String!, $language: String!, $locale: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        articles: allStrapiArticle(filter: { tags: { elemMatch: { slug: { eq: $slug } } }, locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    title
                    slug
                    order
                    locale
                    tags {
                        id
                        name
                        slug
                        order
                    }
                }
            }
        }
        tag: strapiTags(slug: { eq: $slug }) {
            id
            name
            slug
            order
        }
        subcategories: allStrapiSubcategory(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    name
                    slug
                    order
                    locale
                    articles {
                        id
                        title
                        slug
                        order
                        locale
                    }
                    category {
                        id
                        name
                        slug
                        order
                        locale
                    }
                }
            }
        }
    }
`;
