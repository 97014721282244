import * as React from "react";
import { navigate } from "gatsby";
import { styled } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { Container, Box, Grid, Paper, Typography, Divider, Button, Chip, Breadcrumbs } from "@mui/material";

const TagList = ({ tags, articles }) => {
    const { language, languages, defaultLanguage, originalPath, path, siteUrl } = useI18next();
    const { t } = useTranslation();

    console.log(tags, articles);

    let sortedTags = tags.sort(function (a, b) {
        return b.order - a.order;
    });
    let sortedArticles = articles.sort(function (a, b) {
        return b.order - a.order;
    });

    let urlLanguageCode = "";

    if (language != defaultLanguage) {
        urlLanguageCode = `${language}/`;
    }

    const openArticle = slug => event => {
        window.open(`${siteUrl}/${urlLanguageCode}${slug}`, "_self");
    };

    const openTag = slug => event => {
        window.open(`${siteUrl}/${urlLanguageCode}tag/${slug}`, "_self");
    };

    return (
        <Container disableGutters maxWidth={false}>
            <Box display={"flex"} alignContent={"center"} flexDirection={"column"} gap={8} sx={{ m: { xs: 0, md: 4 } }}>
                {sortedTags.map(tag => (
                    <Grid
                        style={{
                            boxShadow: "0px 12px 16px rgba(0, 0, 0, 0.04), 0px 4px 56px rgba(0, 0, 0, 0.04)",
                            borderRadius: 8,
                            minHeight: 200,
                        }}
                        key={tag.id}
                        container
                    >
                        <Grid xs={12} sm={4} p={2} flexDirection={"column"} item display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Box component={"span"} sx={{ cursor: "pointer" }} onClick={openTag(tag.slug)}>
                                    <Typography style={{ fontSize: 24 }} color="text.primary">
                                        {tag.name}
                                    </Typography>
                                </Box>
                            </Breadcrumbs>
                            <Chip color="primary" label={`${sortedArticles.length} ${t("sections")}`} />
                        </Grid>
                        <Divider sx={{ display: { xs: "none", sm: "flex" } }} orientation="vertical" light variant="middle" flexItem style={{ marginRight: "-1px" }} />
                        <Grid item xs={12} sm={8} p={2}>
                            <Box>
                                {sortedArticles.map(article => (
                                    <Box
                                        key={article.id}
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        component={"span"}
                                        p={1}
                                        sx={{ cursor: "pointer" }}
                                        fullWidth
                                        onClick={openArticle(article.slug)}
                                    >
                                        <Typography style={{ fontSize: 16 }}>{article.title}</Typography>
                                        <ArrowForwardIosIcon />
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                ))}
            </Box>
        </Container>
    );
};

export default TagList;
